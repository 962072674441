import ReactPlayer from "react-player";
import "../../../App.css";
import { Container, Row, Col, h6, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TeamSlider = () => {
  return (
    <div className="TeamSlider">
      <div className="TeamHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7}>
              <h1 className="left gold">Health & Safety</h1>
              <h6 className="white">
                {" "}
                Staff wear masks | Staff get temperature checks | Staff required
                to disinfect surfaces between visits
              </h6>
              <h1 className="left gold whitespace">Business as a Mission</h1>
              <h6 className="white">
                {" "}
                We are a team who seeks direction from the Holy Spirit, lead by
                leaders dedicated to using our time, talent, and money to meet
                the community's physical and spiritual needs to advance God's
                purpose.
              </h6>
            </Col>
            {/* <Col xs={12} sm={4} md={5} className="rightImg">
            </Col> */}

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
