import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function DirectionCard() {
  return (
    <Card>
      <a
        aria-label="Google Map"
        href="https://goo.gl/maps/EPFwCo26hR3VNAQy8"
        className="none-link"
        target="_blank"
      >
        <CardActionArea className="color-scheme">
          <CardMedia
            component="img"
            height="140"
            image="/static/get-direction.png"
            width="100%"
            alt="Get Direction"
            loading="lazy"
            title="Don Sol Mexican Grill | Carbondale, IL Get Direction"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              Get Direction
            </Typography>
            <Typography gutterBottom variant="h6" component="div">
              715 N Giant City Rd
              <br></br>
              Carbondale, IL 62902
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
}
