import "../../../App.css";
import { Container, Row, Col, h6, p, h2, div } from "react-bootstrap";

export const HomemadeSlider = () => {
  return (
    <div className="HomemadeSlider">
      <div className="HomemadeHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7}>
              <h1 className="left gold">We aren't just fast food</h1>
              <h6 className="white">
                {" "}
                We take time to make homemade items on the menu, daily. We make our own beans, seasoned rice, melted cheese, and tortillas on site.
              </h6>
              <h1 className="left gold whitespace">Come in to taste the Don Taco difference!</h1>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
