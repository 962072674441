import "../../../App.css";
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
// import center from '../../../assets/css/custom.css';
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import aboutimg1 from "../../../assets/images/about-img-01.jpg";
import aboutimg2 from "../../../assets/images/about-img-02.jpg";
import aboutimg3 from "../../../assets/images/about-img-03.jpg";
import LovingAllPeople from "../../../assets/images/loving-all-people-icon.png";
import CreatingSuperheroes from "../../../assets/images/creating-superheroes-icon.png";
import ExplodingTheBox from "../../../assets/images/exploding-the-box-icon.png";
import ImpactingTheWorld from "../../../assets/images/impacting-the-world-icon.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const About = () => {
  useEffect(() => {
    document.title = "About Us: AJ's Keto Factory is a Kingdom Business.";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            About Don Sol Mexican Grill | Carbondale, IL Mexican Restaurant
          </title>
          <link rel="canonical" href="/about" />
          <meta
            name="description"
            content="Don Sol Mexican Grill is a locally and family owned business that was established on May 20th, 2007. We have been going strong since then as we're dedicated to our community."
          />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <col className="whitespace"></col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                Tacos, Burritos & other Mexican dishes offered in an upbeat
                cantina with colorful decor.{" "}
              </Typography>
              <br />
              <h1 className="gold">
                Not Just Another
                <br></br>
                Mexican Restaurant
              </h1>
            </Col>
            <Col xs={8}>
              <Typography variant="body1" className="white">
                {" "}
                Our menu offers various dishes including ground beef, chicken,
                steak, grilled vegetables, pork, seafood, vegetarian selections,
                and kids meals.
              </Typography>
              <br />
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
            
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div class="about-info-left">
              <h2>Service Options:</h2>
              <ul className="paddingSpecial">
                <li>Outdoor seating</li>
                <li>Curbside pickup</li>
                <li>No delivery</li>
              </ul>
              <h2>Health & safety:</h2>
              <ul className="paddingSpecial">
                <li>Staff wear masks</li>
                <li>Staff required to disinfect surfaces between visits</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography variant="h4">
                Enjoy Traditional Mexican Cuisine at Our Locally Owned
                Restaurant
              </Typography>
              <Typography variant="boby1">
                Don Sol Mexican Grill is a locally and family owned business
                that was established on May 20th, 2007. We have been going
                strong since then as we're dedicated to our community. We serve
                traditional Mexican dishes made using the freshest ingredients
                and our own recipes. During the summer, we buy fresh produce
                from local community gardens. We also buy our meat from a local
                meat market throughout the year.
              </Typography>
              <Typography variant="boby1">
                {""}
                <br />
                <br />
                Our restaurant has been recognized by the community as well. In
                2017, we received the Small Business of the Year Award. And in
                April of 2018, we were inducted into the Donor Recognition Hall
                of Fame.{" "}
              </Typography>
              <Typography variant="boby1">
                {" "}
                <br />
                <br />
                To partake in our wonderful dining experience, please{" "}
                <a
                  aria-label="Google Map"
                  href="https://goo.gl/maps/EPFwCo26hR3VNAQy8"
                  className="red-link"
                  target="_blank"
                >
                  visit us
                </a>{" "}
                today!{" "}
              </Typography>
              <br />

              <Typography variant="h4" className="whitespace">
                Providing a Great Dining Experience to All
              </Typography>
              <Typography variant="boby1">
                We're a family-friendly restaurant providing delicious and
                authentic Mexican food. We have spacious seating and can
                accommodate large groups, too! For business luncheons and
                birthday parties, you can use our private room for a private
                dining experience. An outdoor patio is also available.
                <br />
                <br />
                You can also hire us to cater your event. From pickup to
                full-service catering, we can do them all. We have also started
                a food truck that you can reserve for your event.
                <br />
                <br />
                We constantly update our menu and ensure that you get great
                service and are satisfied with our service every time you visit
                us! And if you have a food allergy, we can prepare our food
                according to your restrictions and specific needs.
              </Typography>
            </div>
            <div className="inner-about-gallery  ">
              <div className="col-lg-12 about-info-right">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title=" Don Sol Mexican Grill About Us Image1"
                    src={aboutimg1}
                    alt="Don Sol Mexican Grill About Us Image1"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Don Sol Mexican Grill About Us Image2"
                    src={aboutimg2}
                    alt="Don Sol Mexican Grill About Us Image2"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title="Don Sol Mexican Grill About Us Image3"
                    src={aboutimg3}
                    alt="Don Sol Mexican Grill About Us Image3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <Col xs={12} className="whitespace">
            <Typography variant="h4" className="dark">
              The Why of AJ's Keto Factory - Purpose
            </Typography>
          </Col>
          <Col xs={10} className="title">
            <Typography variant="boby1">
              {" "}
              AJ Slider's purpose is to love and serve others through excellence
              in every encounter,. Changing lives, one slider at a time!
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video"
              url="https://www.youtube.com/watch?v=f4F9kUTmluY"
            />
          </div>
          <Col xs={12} className="whitespace ">
            <Typography variant="h4" className="dark">
              The Why of AJ's Keto Factory - The Food
            </Typography>
          </Col>
          <Col xs={10} className=" title">
            <Typography variant="boby1">
              {" "}
              Customer focus has been expanded to serve those with celiac
              disease, gluten intolerance, and dairy/lactose intolerance.
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video "
              url="https://www.youtube.com/watch?v=wHIk_-ZVAmw"
            />
          </div>
          <Col xs={12} className="whitespace">
            <Typography variant="h4" className="dark">
              The Why of AJ's Keto Factory - The People
            </Typography>
          </Col>
          <Col xs={10} className=" title">
            <Typography variant="boby1">
              {" "}
              In this video, Lance Bell explains the purpose of the business -
              serving people and impacting the world for Jesus Christ.
            </Typography>
          </Col>
          <div className="col-lg-12 full-width">
            <ReactPlayer
              className=" about video"
              url="https://www.youtube.com/watch?v=ddpU4TWiGYs"
            />
          </div>*/}
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default withRouter(About);