import "../../../App.css";
import { Container, Row, Col, h6, p, h2, div } from "react-bootstrap";

export const SpecialsSlider = () => {
  return (
    <div className="SpecialsSlider">
      <div className="SpecialsHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} sm={8} md={7}>
              <h1 className="left gold">In the mood for something else?</h1>
              <h6 className="white">
                {" "}
                No problem! We've got you covered, no matter what you are craving for.
              </h6>
              <h1 className="left gold whitespace">Call: <a aria-label="Call Phone Number (618) 549-3777" href="tel:(618) 549-3777" className="red-link">618-549-3377</a> or <a aria-label="Call Phone Number (618) 549-3777" href="tel:(618) 351-1177" className="red-link">618-351-1177</a></h1>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
