import React, { useState } from "react";
import {
  Card,
  CardContent,
  Button,
  Stack,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { resolver } from "../../../utils/helpers";
import { BaseInput } from "../../../components/hookform-inputs";
import emailjs from "@emailjs/browser";
import { Input } from "../../../components";
import axios from "axios";

const requiredMessage = "This field is required.";
const schema = {
  name: Yup.string()
    .min(2, "Please enter at least 2 characters")
    .required(requiredMessage),
  email: Yup.string()
    .email("Please enter a valid email")
    .required(requiredMessage),
  message: Yup.string().required(requiredMessage),
};

export default function ContactCard() {
  const [value, setValue] = useState("Write your Message");
  const [disabled, setDisabled] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
  });

  const formMethods = useForm({
    resolver: resolver(schema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  // Get only important methods
  const { handleSubmit, reset } = formMethods;

  const onSubmit = async (formData) => {
    setDisabled(true);
    const body = {
      email: formData.email,
      contactEmail: process.env.REACT_APP_CONTACT_US_EMAIL,
      siteName: process.env.REACT_APP_RESTAURANT_NAME,
      message: formData.message,
    };
    try {
      // axios.post(`https://ecommv2.servingintel.com/customer/contact-us/{siteId}=${process.env.REACT_APP_SITE_NAME}`, {body}).then(res => console.log('Posting data', res)).catch(err => console.log(err))

      const response = await axios.post(
        `https://ecommv2.servingintel.com/customer/contact-us/${process.env.REACT_APP_SITE_ID}`,
        body
      );
      console.log("Success", response);

      setAlertData({
        type: "success",
        message: "Thanks for your message, we will reply as soon as we can.",
      });
    } catch (error) {
      console.log("Error", error);
      setAlertData({
        type: "error",
        message: error.text,
      });
    } finally {
      setDisabled(false);
      reset({
        name: "",
        email: "",
        message: "",
      });
      setTimeout(() => {
        setAlertData({
          type: "",
          message: "",
        });
      }, 5000);
    }
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h3" gutterBottom component="div">
          Write To Us
        </Typography>
        {alertData.type && (
          <Alert
            sx={{
              margin: "16px 0",
            }}
            severity={alertData.type}
          >
            {alertData.message}
          </Alert>
        )}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={3}>
              <Input
                variant="outlined"
                name="name"
                placeholder="Enter your name"
                autoComplete="off"
                disabled={disabled}
              />
              <Input
                variant="outlined"
                name="email"
                placeholder="Enter your email"
                autoComplete="off"
                disabled={disabled}
              />
              <Input
                variant="outlined"
                name="message"
                placeholder="Enter your message"
                autoComplete="off"
                disabled={disabled}
                multiline={true}
                rows={4}
              />
              {disabled ? (
                <Button
                  fullWidth
                  disabled
                  type="button"
                  sx={{
                    display: "flex",
                    gap: 2,
                    background: "gray",
                  }}
                >
                  <CircularProgress color="inherit" /> <span>Send</span>
                </Button>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  Send Message
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
}
