import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TacosCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card id="quickmenu">
      <CardHeader title="Tacos" />
      <CardMedia
        component="img"
        height="194"
        image="/static/images/Tacos.jpg"
        alt="Tacos"
      />
      <CardContent>
        <Typography fontSize="1.125rem" color="text.secondary">
          All tacos are made fresh- meats and/or veggies are grilled while you
          wait. Traditional toppings (cilantro & onion) are available upon
          request in place of lettuce and cheese. Seafood is topped with pico de
          gallo.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ul className="paddingSpecial">
            <li>Refried Beans Taco</li>
            <li>Ground Beef Taco</li>
            <li>Veggie Taco</li>
            <li>Steak Taco</li>
            <li>Chicken Taco</li>
            <li>Barbacoa Taco</li>
            <li>Al Pastor Taco</li>
            <li>Chorizo Taco</li>
            <li>Carnitas Taco</li>
            <li>Fajita Taco (chicken or steak)</li>
            <li>Shrimp Taco</li>
            <li>Tilapia Taco</li>
          </ul>
          <Typography>
            ***indicates Vegetarian items on this page. Any item listed as
            "Veggie" includes grilled bell pepper, onion, mushroom, broccoli,
            zucchini, and yellow squash.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
