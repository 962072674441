import Menu from "./components/Menu";
import { MenuNavigation } from "../components/MenuNavigation";
import { QuickMenuSlider } from "../components/QuickMenuSlider";
import { Footer } from "../components/Footer";
import BlankSpaceTen from "../components/BlankSpaceTen";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

export const QuickMenu = () => {
  useEffect(() => {
    document.title = "Quick Menu";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="QuickMenu">
      <MenuNavigation />
      <QuickMenuSlider />
      <BlankSpaceTen />
      <Menu />
      <BlankSpaceTen />
      <Footer />
    </div>
  );
};
export default withRouter(QuickMenu);
