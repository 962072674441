import showcaseimg from "../../../../../assets/images/about-img-left.png";
import showcaseimg1 from "../../../../../assets/images/showcase-icon.png";
import "../../../../../App.css";
// import whitespace from "../../../../../assets/css/custom.css";
import { Container, Row, Col, Button, h1, h2, p, h3, hr } from "react-bootstrap";

export const ShowCase = () => {
  return (
    <Container className="showcase">
      <Row>
        <Col xs={6} md={6}>
          <img width="100%" height="100%" title="Don Sol Mexican Grill Showcase Image Left" className="img-sm" src={showcaseimg} alt="Don Sol Mexican Grill Showcase Image Left" loading="lazy"/>
        </Col>
        <Col xs={6} md={5}>
          <div className="left margin-top-20">
            <h1>
            Enjoy Traditional Mexican Food at Don Sol Mexican Grill.
            </h1>
            <h2 className="margin-top-20">Get to know us</h2>
          </div>
          <div className="left margin-top-20">
            <p>
              {" "}
              When you've got a craving for traditional Mexican cuisine, visit
              Don Sol Mexican Grill. Our locally and family owned restaurant
              offers delicious, authentic Mexican food in a family-friendly
              atmosphere.
            </p>
          </div>
          <div className="showcase-right-text left">
            <Col xs={6} md={3} className="img-icon">
              <img width="100%" height="100%" title="Don Sol Mexican Grill Showcase Icon"  src={showcaseimg1} size="25" alt="Don Sol Mexican Grill Showcase Icon" loading="lazy"/>
            </Col>
            <p>
              <h3> Our Menu </h3>
              <span>Authentic Mexican food. Burritos. Enchiladas. Tacos.
                Chimichangas. Lunch and dinner. Catering. Dine-in, carryout, or
                delivery.</span>
              <br></br>
              <span><Button
                className="btn-red margin-top-10 margin-bottom-10"
                href="/menu"
              >
                Order Now{" "}
              </Button>
              </span>
              </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};