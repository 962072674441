import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BurritoCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card id="quickmenu">
      <CardHeader title="Burritos" />
      <CardMedia
        component="img"
        height="194"
        image="/static/images/Burrito.jpg"
        alt="Burrito"
      />
      <CardContent>
        <Typography fontSize="1.125rem" color="text.secondary">
          All burritos are made with a 12" flour tortilla, include refried
          beans, melted cheese, lettuce, & tomatoes. All ingredients are
          prepared on site and grilled while you wait.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ul className="paddingSpecial">
            <li>Bean & Cheese Burrito</li>
            <li>Veggie Burrito</li>
            <li>Ground Beef Burrito</li>
            <li>Barbacoa Burrito</li>
            <li>Steak Burrito</li>
            <li>Chicken Burrito</li>
            <li>Fajita Burrito (chicken or steak)</li>
            <li>Carnitas Burrito</li>
            <li>Chorizo Burrito</li>
            <li>Al Pastor Burrito</li>
            <li>Shrimp Burrito</li>
            <li>Don Taco Burrito</li>
          </ul>
        </CardContent>
      </Collapse>
    </Card>
  );
}
