import "../../../App.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";

export const ContactSlider = () => {
  return (
    <div className="ContactSlider">
      <div className="ContactHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} lg={7} className="whitespace">
              <Typography className="white">
                {" "}
                Authentic Mexican Cuisine | Family Friendly Atmosphere |
                Catering Services Available
              </Typography>
              <h1 className="left gold blankspace">
                Want something different? Try Don
                <br></br>
                Sol Mexican Grill
              </h1>

              <Typography className="white blankspace">
                {" "}
                The place to be when it comes to Mexican restaurants in Carbondale, IL. The restaurant offers a family atmosphere, clean
                environment, friendly staff, and a variety of food items and
                drinks.
              </Typography>

              {/*<Button
                className="btn-red whitespace margin-bottom-10"
                href="/register"
              >
                Join our Loyalty Program{" "}
              </Button>*/}
            </Col>
            {/*<Col xs={12} lg={5} className="PhonerightImg"></Col>*/}

            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};