import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { MenuNavigation } from "../components/MenuNavigation";
import { HomemadeSlider } from "../components/HomemadeSlider";
import { Footer } from "../components/Footer";
import BlankSpaceTen from "../components/BlankSpaceTen";
import Rice from "../../../assets/images/rice.png";
import Tortillas from "../../../assets/images/tortillas.png";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

export const Homemade = () => {
  useEffect(() => {
    document.title = "Homemade";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="Homemade">
      <MenuNavigation />
      <HomemadeSlider />
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className="whitespace center title">
              Seasoned Rice, Made Daily
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="left">
            <h5>
              Each morning, we start by making rice before you even walk in the
              door. We use high quality, fresh ingredients to ensure your rice
              is seasoned perfectly. Perfect as a side on a combo plate or in
              one of our delicious burritos.
            </h5>
          </Col>

          <Col xs={6} className="right">
            <p>
              <img width="100%" src={Rice} alt="Seasoned Rice, Made Daily" />
            </p>
          </Col>
        </Row>
        <col className="whitespace"></col>
        <Row>
          <Col xs={12}>
            <h2 className="whitespace center title">
              Hand-rolled Tortillas, On Site
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={6} className="left">
            <p>
              <img
                width="100%"
                src={Tortillas}
                alt="Hand-rolled Tortillas, On Site"
              />
            </p>
          </Col>
          <Col xs={6} className="right">
            <h5>
              We offer handmade and hand-rolled tortillas at our location in
              Carbondale. Made daily and cooked to perfection. You have to try
              them to believe the taste!
            </h5>
          </Col>
        </Row>
      </Container>
      <BlankSpaceTen />
      <Footer />
    </div>
  );
};
export default withRouter(Homemade);
