import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";
import Slider1 from "../../../../../assets/images/header_img_1.jpg";
import Slider2 from "../../../../../assets/images/header_img_2.jpg";
import Slider3 from "../../../../../assets/images/header_img_3.jpg";
import Slider4 from "../../../../../assets/images/header_img_4.jpg";
import Slider5 from "../../../../../assets/images/header_img_5.jpg";
import React, { useEffect } from 'react'

export const HomepageSlider = () => {
    
useEffect(() => {
document.title = "Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available"
}, [])    
    
  return (
    <div className="mobileHomeBG">
      <Carousel fade>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider1} alt="First slide" />
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Carbondale, IL Mexican Cuisine</h1>
          <p>Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</p>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider2} alt="Second slide" />
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Carbondale, IL Mexican Cuisine</h1>
          <p>Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</p>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slider3} alt="Third slide" />
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Carbondale, IL Mexican Cuisine</h1>
          <p>Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</p>
          </div>
        </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slider4} alt="Forth slide" />
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Carbondale, IL Mexican Cuisine</h1>
          <p>Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</p>
          </div>
        </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={Slider5} alt="Fifth slide" />
          <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
          <div className="slider-caption">
          <h1>Carbondale, IL Mexican Cuisine</h1>
          <p>Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</p>
          </div>
        </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
