import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImgListCard() {
  return (
    <ImageList
      sx={{ width: 500, height: 450 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.alt}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: '/static/1.jpg',
    title: 'Don Sol Mexican Grill Image001',
    rows: 2,
    cols: 2,
    alt: 'Don Sol Mexican Grill Image1',
  },
  {
    img: '/static/2.jpg',
    title: 'Don Sol Mexican Grill Image002',
    alt: 'Don Sol Mexican Grill Image2',
  },
  {
    img: '/static/3.jpg',
    title: 'Don Sol Mexican Grill Image003',
    alt: 'Don Sol Mexican Grill Image3',
  },
  {
    img: '/static/4.jpg',
    title: 'Don Sol Mexican Grill Image004',
    cols: 2,
    alt: 'Don Sol Mexican Grill Image4',
  },
{
    img: '/static/5.jpg',
    title: 'Don Sol Mexican Grill Image005',
    cols: 2,
    alt: 'Don Sol Mexican Grill Image5',
  },
  {
    img: '/static/6.jpg',
    title: 'Don Sol Mexican Grill Image006',
    author: '',
    rows: 2,
    cols: 2,
    alt: 'Don Sol Mexican Grill Image6',
  },
  {
    img: '/static/7.jpg',
    title: 'Don Sol Mexican Grill Image007',
    alt: 'Don Sol Mexican Grill Image7',
  },
  {
    img: '/static/8.jpg',
    title: 'Don Sol Mexican Grill Image008',
    alt: 'Don Sol Mexican Grill Image8',
  },
  {
    img: '/static/9.jpg',
    title: 'Don Sol Mexican Grill Image009',
    rows: 2,
    cols: 2,
    alt: 'Don Sol Mexican Grill Image9',
  },
  {
    img: '/static/10.jpg',
    title: 'Don Sol Mexican Grill Image010',
    alt: 'Don Sol Mexican Grill Image10',
  },
  {
    img: '/static/11.jpg',
    title: 'Don Sol Mexican Grill Image011',
    alt: 'Don Sol Mexican Grill Image11',
  },
  {
    img: '/static/12.jpg',
    title: 'Don Sol Mexican Grill Image012',
    cols: 2,
    alt: 'Don Sol Mexican Grill Image12',
  },

];