import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Row, Col } from "react-bootstrap";
import TacosCard from "./Tacos";
import BurritoCard from "./Burrito";
import FriesCard from "./Fries";
import TortasCard from "./Tortas";
import BowlsCard from "./Bowls";
import ChimichangasCard from "./Chimichangas";

const theme = createTheme();

export default function Menu() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Container>
            <Typography
              component="h3"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Quick Menu
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Any item listed as "Veggie" includes grilled bell pepper, onion,
              mushroom, broccoli, zucchini, and yellow squash.
            </Typography>
          </Container>
        </Box>
        <Container fluid="md">
        <Row>
          <Col xs={12} sm={4} className="whitespace left">
          <TacosCard /> 
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
          <BurritoCard /> 
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
          <FriesCard /> 
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
          <TortasCard /> 
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
          <BowlsCard /> 
          </Col>
          <Col xs={12} sm={4} className="whitespace left">
          <ChimichangasCard /> 
          </Col>          
        </Row>
      </Container>        
      </main>
    </ThemeProvider>
  );
}
