import ReactPlayer from "react-player";
import "../../../App.css";
import {
  Container,
  Card,
  Row,
  Col,
  h5,
  p,
  h2,
  div,
  Alert,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";

export const OrderSlider = () => {
  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Don Sol Mexican Grill | Carbondale, IL Mexican Restaurant
          </title>
          <link rel="canonical" href="/menu" />
          <meta
            name="description"
            content="Take a break from your hectic work schedule and have an appetizing lunch at Don Sol Mexican Grill. You'll find a great selection of foods on our menu, including many of your favorite Mexican dishes. If you can't find the food that you're looking for, you can speak to our staff. Rest assured that we'll do our best to accommodate your needs. Carryout and catering options are available, too!"
          />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col sm={12} lg={8}>
            <Typography variant="body1" className="white whitespace">
                {" "}
                Tacos, Burritos & other Mexican dishes offered in an upbeat cantina with colorful decor.
              </Typography> <br/>
              <h1 className="left gold">Authentic Mexican Cuisine | Family Friendly Atmosphere | Catering Services Available</h1>
              <br/>
              <Typography variant="body1" className="white">
                {" "}
                The place to be when it comes to Mexican restaurants in Carbondale, IL. The restaurant offers a family atmosphere, clean environment, friendly staff, and a variety of food items and drinks.
              </Typography>
            </Col>
            <Col sm={0} lg={4}>
              {/*<Card>
        <Card.Img src="/static/1.jpg" />
    </Card> */}
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
