import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { AddressMap } from "../components/AddressMap";
import { MenuNavigation } from '../components/MenuNavigation';
import {Footer} from '../components/Footer';
import BlankSpace from "../components/BlankSpace";
import PhotoGalleryList from "../components/PhotoGalleryList";
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@material-ui/core/Typography";

export const PhotoGallery = () => {
    
// useEffect(() => {
// document.title = "Photo Gallery"
// }, [])    
      
  return (

    <div className="PhotoGallery">
      <MenuNavigation/>
      <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Photo Gallery: Don Sol Mexican Grill | Carbondale, IL Mexican Restaurant</title>
            <link rel="canonical" href="/photo-gallery" />
            <meta
              name="description"
              content="If you’re in search of mouthwatering Mexican food, drop by Don Sol Mexican Grill today. Prepared from the freshest of ingredients, our food will surely uplift your mood. We’re a local, family owned business with a reputation for providing excellent customer service. Dine-in, carryout, delivery, and catering are all available!"
            />
          </Helmet>
        </HelmetProvider>      
      <div className="HeaderImg">
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12} className="whitespace">
            <h1 className="gold center">Authentic Mexican Cuisine | Family Friendly Atmosphere |
                Catering Services Available</h1>
            </Col>

           
          </Row>
        </Container>
      </div>
      <BlankSpace/>
      <Container>
      <Col xs={10} className="center">
              <Typography component="h2" variant="h6" className="title whitespace">If you’re in search of mouthwatering Mexican food, drop by Don Sol
            Mexican Grill today. Prepared from the freshest of ingredients, our
            food will surely uplift your mood. We’re a local, family owned
            business with a reputation for providing excellent customer service.
            Dine-in, carryout, and catering are all available! To
            learn more or to place an order, please{" "}
            <a
              aria-label="Call Phone Number +1 618-351-0002"
              href="tel:+1 618-351-0002"
              className="red-link"
              target="_blank"
            >
              contact us
            </a>{" "}
            today!</Typography>
      </Col>
      <BlankSpace/>
        <Row className="whitespace">
          <Col>
      <PhotoGalleryList/> 
          </Col>
        </Row>
      </Container>
      <BlankSpace />
      {/* <AddressMap /> */}
      <Footer/>
    </div>

  );
};
