import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { MenuNavigation } from "../components/MenuNavigation";
import { SpecialsSlider } from "../components/SpecialsSlider";
import { Footer } from "../components/Footer";
import BlankSpaceTen from "../components/BlankSpaceTen";
import Tilapia from "../../../assets/images/tilapia-tacos.jpg";
import Nacho from "../../../assets/images/nacho-special.jpg";
import Combo from "../../../assets/images/combo-plates.jpg";
import Alert from "react-bootstrap/Alert";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

export const Specials = () => {
  useEffect(() => {
    document.title = "Specials, Discount, and Coupons";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="Specials">
      <MenuNavigation />
      <SpecialsSlider />
      <BlankSpaceTen />
      <Container>
        <Row>
          <Col xs={6} className="left">
            <h5>
              Stop by for our Nacho special! $5.99 every Monday when you dine
              in, from 11am - 2am. $5.99 price is valid for the following
              toppings:
              <ul className="paddingSpecial">
                <li>Ground Beef</li>
                <li>Steak</li>
                <li>Chicken</li>
                <li>Barbacoa</li>
                <li>Veggie</li>
              </ul>
            </h5>
          </Col>

          <Col xs={6} className="right">
            <p>
              <img width="100%" src={Nacho} alt="Nacho special" />
            </p>
          </Col>
        </Row>
        <BlankSpaceTen />

        <Row>
          <Col xs={6} className="left">
            <h5>
              Try our tilapia tacos with fresh pico de gallo on handmade
              tortillas- you won't regret it!
            </h5>
          </Col>
          <Col xs={6} className="right">
            <p>
              <img width="100%" src={Tilapia} alt="Tilapia Tacos" />
            </p>
          </Col>
        </Row>
        <BlankSpaceTen />
        <Row>
          <Col xs={6} className="left">
            <h5>
              Try one of our delicious combo plates for lunch or dinner! All
              combo plates include your choice of main dish, along with a side
              of seasoned rice, refried beans, chips, and a fountain soft drink.
              <ul className="paddingSpecial">
                <li>2 Tacos Combo Plate</li>
                <li>Burrito Combo Plate</li>
                <li>Quesadilla Combo Plate</li>
                <li>Chimichanga Combo Plate</li>
                <li>Fajitas Combo Plate</li>
                <li>Steak & Cheese Combo Plate</li>
                <li>Chicken & Cheese Combo Plate</li>
                <li>Tilapia Combo Plate</li>
                <li>Grilled Shrimp Combo Plate</li>
                <li>3 Roll Taquitos Combo Plate</li>
              </ul>
            </h5>
            <Alert variant="success">
              *Vegetarian option available on the tacos, burrito, quesadilla,
              chimichanga, and fajita combo plates.
            </Alert>
          </Col>

          <Col xs={6} className="right">
            <p>
              <img width="100%" src={Combo} alt="Delicious Combo Plates" />
            </p>
          </Col>
        </Row>
      </Container>
      <BlankSpaceTen />
      <Footer />
    </div>
  );
};
export default withRouter(Specials);
